import React, { memo, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { msHelper } from "../../../services/ms-helper.service";

interface AmenitiesCheckBoxProps {
  amenity: {
    id: number;
    amenity: string;
  };
  amenities: string[];
}

const AmenitiesCheckBox: React.FC<AmenitiesCheckBoxProps> = ({
  amenity,
  amenities,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (amenities.length > 0) {
      const findAmenity =
        msHelper.amenitiesFind(amenity.id, JSON.parse(amenities.toString())) ===
        1;
      setChecked(findAmenity);
    }
  }, [amenity, amenities]);

  return (
    <>
      <Form.Check
        name="amenities"
        className="mycheckbox"
        value={amenity.id.toString()}
        inline
        label={amenity.amenity}
        type={"checkbox"}
        checked={checked}
        onChange={() => setChecked(!checked)}
        id={`inline-checkbox-${amenity.amenity}`}
      />
    </>
  );
};

export default memo(AmenitiesCheckBox);
