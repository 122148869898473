import React, { memo, useEffect, useRef, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import parse from "html-react-parser";
import { Api } from "../../../services/axios.service";

interface EditVendorModalProps {
  show: boolean;
  toggleModal: () => void;
  refreshList: () => void;
  item: {
    id: number;
    full_name: string;
    mobile: string;
    vendor_email: string;
    silver_email_id: string;
    pass: string;
  };
}

const EditVendorModal: React.FC<EditVendorModalProps> = ({
  show,
  toggleModal,
  refreshList,
  item,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [changePassword, setChangePassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormError("");
    setFormSuccess("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as HTMLFormElement);
    const token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("id", item.id.toString());

    Api.post("Admin/Vendors/update_vendor", formData).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          toggleModal();
          setFormError("");
          setFormSuccess("");
        }, 1000);
      } else {
        setFormError(res.data["msg"]);
      }
    });
  };

  useEffect(() => {
    if (show) {
      setChangePassword(false);
    }
  }, [show]);

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          toggleModal();
          setFormError("");
          setFormSuccess("");
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && (
            <div>
              <CAlert color="danger">{parse(formError)}</CAlert>
            </div>
          )}
          {formSuccess && (
            <div>
              <CAlert color="success">{parse(formSuccess)}</CAlert>
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="full_name"
                type="text"
                placeholder="Enter Full Name"
                defaultValue={item.full_name}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="mobile"
                type="number"
                placeholder="Enter Mobile Number"
                defaultValue={item.mobile}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email{" "}
                <small className="text-muted font-weight-bold">
                  (provided by vendor)
                </small>
              </Form.Label>
              <Form.Control
                onChange={handleChange}
                name="vendor_email"
                type="email"
                placeholder="Enter Email"
                defaultValue={item.vendor_email}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email{" "}
                <small className="text-muted font-weight-bold">
                  (generated by silver stays)
                </small>
              </Form.Label>
              <Form.Control
                onChange={handleChange}
                name="silver_email"
                type="email"
                placeholder="Enter Silver's Email"
                defaultValue={item.silver_email_id}
              />
            </Form.Group>
            <Form.Group>
              {/* <Form.Label>Password</Form.Label> */}
              <Form.Check
                id="change_password"
                type="switch"
                name="change_password"
                label="Change Password"
                defaultChecked={false}
                onChange={(e) => {
                  console.log("Value ", e.target.checked);
                  if (e.target.checked) {
                    setChangePassword(true);
                  } else {
                    setChangePassword(false);
                  }
                }}
              />
              <Form.Control
                onChange={handleChange}
                disabled={!changePassword}
                name="pass"
                type="password"
                placeholder="Enter Password"
                defaultValue={""}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Update Profile
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default memo(EditVendorModal);
