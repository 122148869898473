import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../../services/axios.service";

interface AddPlaceModalProps {
  PlaceModal: boolean;
  togglePlaceModal: () => void;
  hotelId: string;
  refreshList: () => void;
}

interface FormErrors {
  place_id?: string;
  place_name?: string;
  lat?: string;
  long?: string;
}

const AddPlaceModal: React.FC<AddPlaceModalProps> = ({
  PlaceModal,
  togglePlaceModal,
  hotelId,
  refreshList,
}) => {
  const [formError, setFormError] = useState<FormErrors>({});
  const [formSuccess, setFormSuccess] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError({});
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);
    let token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("hotel_id", hotelId);
    Api.post("Admin/places/add", formData).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          togglePlaceModal();
          setFormError({});
          setFormSuccess("");
        }, 1000);
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormSuccess("");
  };

  return (
    <div>
      <Modal
        show={PlaceModal}
        onHide={() => {
          togglePlaceModal();
          setFormError({});
          setFormSuccess("");
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Place</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess !== "" && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Place Id</Form.Label>
              <Form.Control
                name="place_id"
                type="number"
                placeholder="Enter place id"
                onChange={handleChange}
              />
              <div className="invalid-feedback d-block">
                {formError.place_id}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Place Name</Form.Label>
              <Form.Control
                name="place_name"
                type="text"
                placeholder="Enter place name"
                onChange={handleChange}
              />
              <div className="invalid-feedback d-block">
                {formError.place_name}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Place Lat</Form.Label>
              <Form.Control
                name="lat"
                placeholder="Enter Place latitude"
                onChange={handleChange}
              />
              <div className="invalid-feedback d-block">{formError.lat}</div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Place Long</Form.Label>
              <Form.Control
                name="long"
                placeholder="Enter Place longitude"
                onChange={handleChange}
              />
              <div className="invalid-feedback d-block">{formError.long}</div>
            </Form.Group>

            <Button variant="primary" type="submit">
              Add Place
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPlaceModal;
