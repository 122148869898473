import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../../services/axios.service";

interface FormErrors {
  restaurant_id?: string;
  restaurant_name?: string;
  lat?: string;
  long?: string;
}

interface AddRestaurantsModalProps {
  RestaurentModal: boolean;
  hotelId: string; // Adjust the type as per your requirement
  toggleRestaurentModal: () => void;
  refreshList: () => void;
}

const AddRestaurantsModal: React.FC<AddRestaurantsModalProps> = ({
  RestaurentModal,
  hotelId,
  toggleRestaurentModal,
  refreshList,
}) => {
  const [formError, setFormError] = useState<FormErrors>({});
  const [formSuccess, setFormSuccess] = useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError({});
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);
    const token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("hotel_id", hotelId);

    try {
      const res = await Api.post("Admin/Restaurants/add", formData);
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          toggleRestaurentModal();
          setFormError({});
          setFormSuccess("");
        }, 1000);
      } else {
        setFormError(res.data["formErrors"]);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <Modal
        show={RestaurentModal}
        onHide={() => {
          toggleRestaurentModal();
          setFormError({});
          setFormSuccess("");
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Restaurant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess !== "" && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Restaurant Id</Form.Label>
              <Form.Control
                name="restaurant_id"
                type="number"
                placeholder="Enter Restaurant id"
              />
              <div className="invalid-feedback d-block">
                {formError.restaurant_id}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Restaurant Name</Form.Label>
              <Form.Control
                name="restaurant_name"
                type="text"
                placeholder="Enter Restaurant name"
              />
              <div className="invalid-feedback d-block">
                {formError.restaurant_name}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Restaurant Lat</Form.Label>
              <Form.Control
                name="lat"
                placeholder="Enter Restaurant latitude"
              />
              <div className="invalid-feedback d-block">{formError.lat}</div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Restaurant Long</Form.Label>
              <Form.Control
                name="long"
                placeholder="Enter Restaurant longitude"
              />
              <div className="invalid-feedback d-block">{formError.long}</div>
            </Form.Group>

            <Button variant="primary" type="submit">
              Add Restaurant
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddRestaurantsModal;
