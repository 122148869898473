import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";

const fields = ["id", "subject", "message", "booking_id", "date", "user_id"];

interface Help {
  id: number;
  subject: string;
  message: string;
  booking_id: number;
  created_at: string;
  user_id: number;
}

const HelpsPage: React.FC = () => {
  const [tableData, setTableData] = useState<Help[]>([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const formData = new FormData();
    const token = localStorage.getItem("token") ?? "";
    formData.append("token", token);

    try {
      const res = await Api.post("Admin/helps", formData);
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error loading data:", error);
      setTableData([]);
    } finally {
      setTableLoading(false);
    }
  };

  return (
    <div>
      <CCol xs="12" lg="12" className={"verticle_center_table"}>
        <CCard>
          <CCardHeader>All Help</CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                responsive
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: Help) => <td>{"#" + item.id}</td>,
                  date: (item: Help) => <td>{item.created_at}</td>,
                  user_id: (item: Help) => (
                    <td style={{ minWidth: 180 }}>
                      <Link
                        to={`/view-user/${item.user_id}`}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon className="ml-2" icon={faEye as any} />
                        {" View User"}
                      </Link>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default HelpsPage;
